<!-- 重写order/new_index.vue -->
<template>
	<div>
		<TableList 
			:columns="tab_table_list[tab_index].columns"
			:slot_table_list="['operation','status','pay_price']" :get_table_list="tab_table_list[tab_index].list_api"
			ref="list" :expandRowByClick="true"
			:expandedRowRender="true"
			:form_data_seo="form_data_seo"
			 @expandedRowsChange="handleExpandedRowsChange($event)"
			 :rowKey="tab_table_list[tab_index].key"
			 :submit_preprocessing="submit_preprocessing"
		>
			<template slot="before">
				<div class="table_before">
					<div class="left">
						<a-tabs type="card" @change="tab_change" class='tab_info'>
							<a-tab-pane :key="0" tab="按订单统计"></a-tab-pane>
							<a-tab-pane :key="1" tab="按配送商统计"></a-tab-pane>
							<a-tab-pane :key="2" tab="按品项统计"></a-tab-pane>
						</a-tabs>
					</div>
					<div class="right_btn">
						<a-button type="primary" class="btn_item" @click="order_set_visible = true">
							订单设置
						</a-button>
						<a @click="report()">
							<a-button class="a_btn">订单导出</a-button>
						</a>
					</div>
				</div>
			</template>
			<template slot="expandedRowRender" slot-scope="data">
				<a-table 
					:columns="tab_table_list[tab_index].columns_sub" 
					:slot_table_list="['operation','status','pay_price']"
					:pagination="false"
					:dataSource="data.record.list"
					rowKey="id"
				>
					<!-- 子表格 -->
					<template slot='operation' slot-scope="text,record,index">
						<!-- 按订单统计-->
					     <span v-if="tab_index === 0">
					         <a v-if="record.is_cancel" @click="cancel_Checkout(record)">取消出库单</a>
					     </span>
						 
						 <!-- 按经销商统计、按品项统计 -->
						 <a v-if="tab_index === 1 || tab_index === 2" @click="go_info(record)">查看详情</a>
					 </template>
					 
					 <template slot="status" slot-scope="text,record,index">
						 <!-- 按订单统计 -->
					     <span v-if="tab_index === 0">{{record.status==0?'待接单':(record.status==1?'待配送':(record.status==2?'待收货':record.status==3?'已完成':'已取消'))}}</span>
						 
						 <!-- 按经销商统计、按品项统计-->
						 <div v-if="tab_index === 1 || tab_index === 2">
							 <span v-if="record.status == 0">待出库</span>
							 <span v-else-if="record.status == 1">出库中</span>
							 <span v-else-if="record.status == 2">已完成</span>
							 <span v-else>已取消</span>
						 </div>
					 </template>
				</a-table>
			</template>
			
			<!-- 主表格 -->
			<template slot='status' slot-scope="data">
				<div v-if="tab_index === 0">
					<span v-if="data.record.status==0">待出库</span>
					<span v-else-if="data.record.status==1">出库中</span>
					<span v-else-if="data.record.status==2">已完成</span>
					<span v-else>已取消</span>
				</div>
			</template>
			<template slot='operation' slot-scope="data">
				<!-- 按订单统计 -->
			    <div v-if="tab_index === 0">
			        <a @click="go_info(data.record)">查看详情</a>
			        <a-divider type="vertical" v-if='data.record.status == 0 && data.record.is_cancel && tab_index === 0' /> 
					<a @click.stop="cancle_order(data.record)"
			            v-if='data.record.status == 0 && data.record.is_cancel && tab_index === 0'
					>
						取消订单
					</a>
					<span v-auth="'change'">
						<a-divider type="vertical" />
						<a @click="go_edit(data.record)">编辑</a>
					</span>
						<!-- <span v-auth="'delete'">
							<a-divider type="vertical" />
							<a-popconfirm title="您确定要删除吗?" v-auth="'delete'" @confirm="del(data.record)">
								<a>删除</a>
							</a-popconfirm>
						</span> -->
			    </div>
				
				<!-- 按经销商统计 -->
				<a-tooltip v-if="tab_index === 1" placement="topLeft" :title="data.record.goods_title.join('  ,  ')"
				    arrow-point-at-center>
				    <span>{{data.record.goods_title[0]}}...</span>
				</a-tooltip>
				
			</template>
			<template slot='pay_price' slot-scope="text,record,index">
			    <span v-if="tab_index === 0">{{Number(record.pay_price).toLocaleString()}}</span>
			</template>
			
		</TableList>
		<EditPop @handleSubmit="orderSubmit" :form_data="order_form_data" :visible.sync="order_set_visible" @handleChange='orderHandleChange'
			:width="'600px'">
			<template #user_id>
				<div class="push_item" v-if="show_user">
					<div>
						<div class="cont_box" v-for="(item,index) in user_list">
							<div class="cont_item">
								<p>姓名：{{item.username}}</p>
							</div>
							<span class="add_man del_iocn">
								<a-icon type="minus-circle" @click='del_member(index)' />
							</span>
						</div>
						<p class="add_man" @click='user_visible = true'>
							添加可取消人员
							<a-icon type="plus-circle" />
						</p>
					</div>
				</div>
			</template>
		</EditPop>
		<SelectUser @submit="SelectUserSubmit" :visible.sync="user_visible"></SelectUser>
	</div>
</template>

<script>
import {
	orderCensus,
	distributorCensus,
	goodsCensus,
	getCheckout,
	censusSub,
	getOrderSetting,
	setOrderSetting,
	cancelOrder,
	cancelCheckout
} from "@/api/order";
import TableList from "@/components/TableList";
import EditPop from "@/components/EditPop";
import SelectUser from "@/components/SelectUser";
import {getGoods,delOrder} from '@/api/cost'
export default {
    name: "Index",
    components: {
       TableList,
	   EditPop,
	   SelectUser
    },
    data() {
        return {
			submit_preprocessing: {
				array_to_string: ['department','customer_type','order_status']
			},
			order_set_visible:false,// 订单设置弹窗是否显示
			user_list:[],// 订单设置弹窗中被选中的客户用户列表
			show_user:true,// “指定人员取消”是否被勾选上了，没有勾选的话则不显示下方的人员
			user_visible:false,// 添加人员弹窗是否显示
			order_form_data: {
			    title: '订单设置',
			    list: [{
			            type: 'select',
			            name: 'auto',
			            title: '自动收货时间',
			            list: [{
			                    key: 15,
			                    value: "15天"
			                },
			                {
			                    key: 30,
			                    value: "30天"
			                },
			                {
			                    key: 60,
			                    value: "60天"
			                },
			                {
			                    key: 0,
			                    value: "不自动收货"
			                }
			            ],
			            options: {},
			        },
			        {
			            type: 'checkbox',
			            name: 'type',
			            title: '可取消订单人员',
			            list: [{
			                    key: 'superior',
			                    value: "直属上级取消"
			                },
			                {
			                    key: 'appoint',
			                    value: "指定人员取消"
			                }
			            ],
			            options: {},
			        },
			        {
			            type: 'slot',
			            name: 'user_id'
			        },
			        {
			            type: 'checkbox',
			            name: 'show_price',
			            title: '经销商显示信息',
			            list: [{
			                key: 1,
			                value: "显示订单总价"
			            }],
			            options: {},
			        },
			    ]
			},
			orderCensus,// 按订单统计
			distributorCensus, // 按经销商统计
			goodsCensus, // 按品项统计
			
			getCheckout,// 获取出库单 按订单统计展开时所请求的接口
			censusSub, // 订单统计 按经销商、品项统计展开时所请求的接口
			
			tab_index:0,// 当前tab选中的列表下标（控制显示tab_table_list中的哪个）
			/**
			 * 该页面的列表被分为了3个tab，该属性记录了每个tab中列表的配置
			 * 从上到下分别是按订单统计、按经销商统计、按品项统计
			 * 需要请求的接口，列表存在的字段，列表展开后子列表的字段
			 * 展开时所请求的接口
			 * TableList 中 rowKey 需要绑定的值
			 */
			tab_table_list:[
				{
					list_api:orderCensus,// 按订单统计
					columns:[
						{
							title: "订单编号",
							dataIndex: "order_no",
						},
						{
							title: "创建时间",
							dataIndex: "create_time",
						},
						{
							title: "业务员",
							dataIndex: "username",
						},
						{
							title: "客户名称",
							dataIndex: "customer_name",
						},

						{
							title: "商品数量",
							dataIndex: "goods_num",
						},
						{
							title: "订单总价",
							dataIndex: "pay_price",
						   
						},
						{
							title: "上级配送商",
							dataIndex: "distributor_name",
						},

						{
							title: "状态",
							dataIndex: "status",
							scopedSlots: {
								customRender: "status"
							}
						},
						{
							title: "操作",
							dataIndex: "operation",
							scopedSlots: {
								customRender: "operation"
							}
						},
					],
					columns_sub:[
						{
							title: "出库单号",
							dataIndex: "checkout_no",
						},
						{
							title: "拆单时间",
							dataIndex: "create_time",
						},
						{
							title: "上级配送商",
							dataIndex: "distributor_name",
						},
						{
							title: "出库数量",
							dataIndex: "goods_num",
						},
						{
							title: "出库金额",
							dataIndex: "pay_price",
							key: 'pay_price',
							scopedSlots: {
								customRender: 'pay_price'
							},
						},
						{
							title: "出库单状态",
							dataIndex: "status",
							key: 'status',
							scopedSlots: {
								customRender: 'status'
							},
						},
						{
							title: "操作",
							dataIndex: "operation",
							key: 'operation',
							scopedSlots: {
								customRender: 'operation'
							},
						},
					],
					sub_api:getCheckout,
					key:'id',
				},
				{
					list_api:distributorCensus,// 按经销商统计
					columns:[
						{
							title: "经销商名称",
							dataIndex: "distributor_name",
						},
						{
							title: "所属办事处",
							dataIndex: "distributor_department",
						},
						{
							title: "物品名称",
							dataIndex: "operation",
							scopedSlots: {
								customRender: "operation"
							}
						},
						// allowance

						{
							title: "订单数量",
							dataIndex: "order_num",
						},
						{
							title: "销售数量",
							dataIndex: "sale_num",
						},
						{
							title: "折让数量",
							dataIndex: "allowance",
						},
						{
							title: "促销数量",
							dataIndex: "promotion",
						},
						{
							title: "提货奖励数量",
							dataIndex: "reward",
						},
						{
							title: "数量合计",
							dataIndex: "total_num",
						},
						{
							title: "总金额",
							dataIndex: "pay_price",
						},
					],
					columns_sub:[
						{
							title: "订单编号",
							dataIndex: "order_no",
						},
						{
							title: "创建时间",
							dataIndex: "date",
						},
						{
							title: "下单人",
							dataIndex: "username",
						},
						{
							title: "下单客户",
							dataIndex: "customer_name",
						},
						{
							title: "订单金额",
							dataIndex: "pay_price",
							key: 'pay_price',
							scopedSlots: {
								customRender: "pay_price"
							}
						},
						{
							title: "商品数量",
							dataIndex: "goods_num",
						},
						{
							title: "状态",
							dataIndex: "status",
							key: 'status',
							scopedSlots: {
								customRender: 'status'
							},
						},
						{
							title: "操作",
							dataIndex: "operation",
							key: 'operation',
							scopedSlots: {
								customRender: 'operation'
							},
						},

					],
					sub_api:censusSub,
					key:'distributor',
				},
				{
					list_api:goodsCensus,// 按品项统计
					columns:[
						{
							title: "商品ID",
							dataIndex: "goods_id",
						},
						{
							title: "物品名称",
							dataIndex: "goods_title",
						},
						{
							title: "销售数量",
							dataIndex: "sale_num",
						},
						{
							title: "折让数量",
							dataIndex: "allowance",
						},
						{
							title: "促销数量",
							dataIndex: "promotion",
						},
						{
							title: "提货奖励数量",
							dataIndex: "reward",
						},
						{
							title: "数量合计",
							dataIndex: "total_num",
						},
						{
							title: "总金额",
							dataIndex: "pay_price"
						},
					],
					columns_sub:[
						{
							title: "订单编号",
							dataIndex: "order_no",
						},
						{
							title: "创建时间",
							dataIndex: "date",
						},
						{
							title: "下单人",
							dataIndex: "username",
						},
						{
							title: "下单客户",
							dataIndex: "customer_name",
						},
						{
							title: "订单金额",
							dataIndex: "pay_price",
							key: 'pay_price',
							scopedSlots: {
								customRender: "pay_price"
							}
						},
						{
							title: "商品数量",
							dataIndex: "goods_num",
						},
						{
							title: "状态",
							dataIndex: "status",
							key: 'status',
							scopedSlots: {
								customRender: 'status'
							},
						},
						{
							title: "操作",
							dataIndex: "operation",
							key: 'operation',
							scopedSlots: {
								customRender: 'operation'
							},
						},
					],
					sub_api:censusSub,
					key:'goods_id',
				}
			],
			prev_expand_row:[],// 此处记录上一次展开的子表格ID
			form_data_seo: {
			    list: [{
			            type: "tree-select",
			            name: "department",
			            title: "部门",
			            options: {},
			            treeData: [],
			            multiple: true
			        },
			        {
			            type: "text",
			            name: "username",
			            title: "业务员",
			            placeholder: '姓名',
			            options: {},
			        },		
			        {
			            type: "text",
			            name: "customer",
			            title: "客户",
			            placeholder: '客户姓名/ID',
			            options: {},
			        },
			        {
			            type: "tree-select",
			            name: "customer_type",
			            title: "客户类型",
			            options: {},
			            treeData: [],
			            multiple: true
			        },
			        {
			            type: "select",
			            name: "goods_id",
			            title: "品项",
			            options: {},
			            list: [],
			        },
			        {
			            type: "text",
			            name: "distributor",
			            title: "上级配送商",
			            placeholder: '上级配送商姓名',
			            options: {},
			        },	
			        {
			            type: "select",
			            name: "distributor_type",
			            title: "去重",
			            options: {},
			            treeData: [],
						list: [
							{
								key: "customer",
								value: "终端"
							},
							{
								key: "wholesale",
								value: "分销"
							}
						]
			        },
			        {
			            type: "text",
			            name: "order_no",
			            title: "订单编号",
			            placeholder: '请输入订单编号',
			            options: {},
			        },
					{
						type: "select",
						title: "订单状态",
						name: "order_status",
						options: {},
						mode:'multiple',
						list: [{
								key: "0",
								value: "待出库"
							},
							{
								key: "1",
								value: "出库中"
							},
							{
								key: "2",
								value: "已完成"
							},
							{
								key: "-1",
								value: "已取消"
							}
						],
					},
			        {
			            type: "range_date",
			            name: "range_date",
			            title: "创建时间",
			            options: {
							initialValue: [this.$moment(new Date(new Date().getTime() - (30 * 24 * 60 * 60 * 1000)), 'YYYY/MM/DD'), this.$moment(new Date(), 'YYYY/MM/DD')],
						},
			            start: {
			                name: 'start_time'
			            },
			            end: {
			                name: 'end_time'
			            },
			        },
			    ],
			    ...this.$config.form_data_seo
			},
		};
    },
    async created() {
		
	
		// 缓存该页面
		this.$keep_route.add(this.$options.name);
		
		this.get_orderseting_info();
		Promise.all([this.$method.get_department(), this.$method.get_group(), this.$method.get_level()]).then(res => {
			this.form_data_seo.list.forEach(item => {
				if (item.name == "department") {
					item.treeData = res[0];
				}
			});
		});
		this.$method.get_group().then(res => {
            this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'customer_type', 'treeData', res)
            //this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'distributor_type', 'treeData', res)
        });
		this.get_goods()
	},
    methods: {
		// 取消订单
		cancle_order(data) {
		    cancelOrder({
		        data: {
		            id: data.id
		        },
		        info: true
		    }).then(res => {
		        this.$refs.list.get_list()
		    })
		},
		get_goods() {
			getGoods().then(res => {
				var goods = [];
				res.data.list.forEach(item=>{
					var g = {key:item.id,value:item.goods_title}
					goods.push(g)
				})
				this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'goods_id', 'list', goods)
			})
		},
		// 查看订单详情
		go_info(data) {
		    this.$router.push({
		        path: 'order_details',
		        query: {
		            id: data.id
		        }
		    })
		},
		// 按订单统计中展开的子表格操作一列：取消出库单
		cancel_Checkout(data) {
		    cancelCheckout({
		        data: {
		            id: data.id
		        },
		        info: true
		    }).then(res => {
		        this.$refs.list.get_list()
		    })
		},
		// 订单设置弹窗中删除已选中的指定人员
		del_member(index){
			this.user_list.splice(index, 1);
		},
		// 订单设置弹窗中点击“添加可取消人员”
		// 弹出人员选择弹窗
		// 人员选择弹窗的确定按钮
		SelectUserSubmit({
			data
		}){
			this.user_list.push(data);
			this.user_visible = false;
		},
		orderSubmit(data) {
		    let ueser_id = []
		    if (this.show_user) {
		        this.user_list.forEach(item => {
		            ueser_id.push(item.id)
		        })
		    }
		    let obj = {
		        "auto": data.values.auto,
		        "cancel": {
		            "type": data.values.type.join(','),
		            "user_ids": ueser_id.join((','))
		        },
		        "show_price": data.values.show_price[0] ? 1 : 0
		    }
		    this.get_orderseting_info()
			setOrderSetting({
			    data: obj,
			    info: true
			}).then(res => {
			    this.order_set_visible = false;
			    this.get_orderseting_info();
			})
		},
		// 订单设置弹窗表单发生改变
		orderHandleChange(event){
			let obj = event.e.join(',');
			if (obj.indexOf("appoint") < 0) {
			    this.show_user = false
			} else {
			    this.show_user = true
			}
		},
		// 获取订单设置
		get_orderseting_info() {
		    getOrderSetting().then(res => {
		        this.user_list = []
		        let obj = res.data.data
		        this.order_form_data.list.forEach(item => {
		            if (item.name == 'auto') {
		                item.options.initialValue = obj.auto
		            }
					
		            if (item.name == 'type') {
		                item.options.initialValue = obj.cancel.type.split(',')
		               if (obj.cancel.type.indexOf("appoint") < 0) {
		                   this.show_user = false
		               } else {
		                   this.show_user = true
		               }
		            }
					
		            if (item.name == 'show_price') {
		                item.options.initialValue = [obj.show_price]
		
		            }
		        })
		        obj.user_data.forEach(item => {
		            this.user_list.push(item)
		        })
		    })
		},
		tab_change(key){
			this.tab_index = key;
			this.prev_expand_row = [];
			this.$nextTick(()=>{
				this.$refs.list.pagination.page=1
				this.$refs.list.get_list();
			})
			if (key == 2) {
				this.form_data_seo.list[3].type = 'hidden'
				this.form_data_seo.list[3].title = ''
			} else {
				this.form_data_seo.list[3].type = 'tree-select'
				this.form_data_seo.list[3].title = '客户类型'
			}
			
			if (key == 1) {
				this.form_data_seo.list[4].type = 'hidden'
				this.form_data_seo.list[4].title = ''
			} else {
				this.form_data_seo.list[4].type = 'select'
				this.form_data_seo.list[4].title = '品项'
			}

			if (key == 0) {
				this.form_data_seo.list[8].type = 'select'
				this.form_data_seo.list[8].title = '订单状态'
			} else {
				this.form_data_seo.list[8].type = 'hidden'
				this.form_data_seo.list[8].title = ''
			}
		},
		// 列表子表格展开
		handleExpandedRowsChange(event){
			// 由于event只有全部展开的列表key，所以我需要配合 prev_expand_row 来判断当前行
			let now_row_id = null; // 当前行的ID
			
			/**
			 * 如果 prev_expand_row 为空数组则代表两种可能
			 * 一种是页面刚进来，所有列表项的展开状态默认为收起
			 * 第二种则是切换了页面
			 */ 
			if(!this.prev_expand_row.length && event.length){
				now_row_id = event[0];
			}else{
				// 只有当前展开行的长度大于上一次展开行长度时才代表是展开
				if(event.length > this.prev_expand_row.length){
					now_row_id = event[event.length - 1];
				}
			}
			this.prev_expand_row = event; // 在本轮判断结束后记录本次操作展开的列表ID，于下次展开时判断当前行ID

			// 如果是 null 则代表本次行展开操作可能是收起操作，因此不执行接下来的接口请求
			if(now_row_id === null){
				return;
			}
			
			// 根据当前tab下标处理后传入sub_api
			let data = {};
			if(this.tab_index === 0){ // 按订单统计
				data = {
					id:now_row_id
				}
			}else if(this.tab_index === 1){ // 按经销商统计
				data = {
					distributor_id:now_row_id // 筛选条件中由于存在 distributor 字段，所以后端约定传入子表格展开接口(censusSub)中的字段为 distributor_id
				}
			}else if(this.tab_index === 2){ // 按品项统计
				data = {
					goods_id:now_row_id
				}
			}
			
			this.tab_table_list[this.tab_index].sub_api({
				data:{
					...data,
					...this.$store.state.table_list_seo_data, // 筛选条件表单中的值(点击查询后才生效)
				}
			}).then(res=>{
				let list = [];
				this.$refs.list.list.forEach((item, index) => {
					if (item[this.tab_table_list[this.tab_index].key] == now_row_id) {
						item.list = res.data.list;
					}
					list.push(item)
				})
				this.$refs.list.set_data('list', list)
			})
		},
		report() {
			let report_key = "";
			if(this.tab_index == 0){
				report_key = "custom_order_list";
			} else if (this.tab_index == 1){
				report_key = "custom_order_distributor";
			} else {
				report_key = "custom_order_goods";
			}
			this.$router.push({
				path:"/report/modify_report",
				query:{
					report_key: report_key,
					...this.$refs.list.seo_data
				}
			});
		},
		del(data) {
			delOrder({
				data:{
					id:data.id
				},
				info:true
			}).then(res=>{
				this.$refs.list.get_list();
			})
		},
		go_edit(data) {
			this.$router.push({
				path:'order_edit',
				query:{
					id:data.id
				}
			})
		}
		
	}
};
</script>

<style lang="less">
@import url("../../assets/less/app.less");
.table_before{
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid #e8e8e8;
	.left{
		position: relative;
		bottom: -1px;
	}
}

.ant-tabs-bar{
	margin: 0!important;
}
.btn_item{
	margin-right: 10px;
}
.push_item {
	margin-left: 115px;
	height: auto;
	margin-top: -20px;

	// line-height: 40px;
	.lable_text {
		width: 70px;
		display: inline-block;
	}

	display: flex;

	.good_item {
		height: 70px;

		p {
			height: 20px;
		}
	}
}
.cont_box {
	align-items: center;
	display: flex;

	.cont_item {
		width: 120px;

		p {
			height: 22px;
			line-height: 22px;
		}
	}

	.del_iocn {
		margin-left: 35px;
		color: #00A3FF;
	}

}
.add_man {
	cursor: pointer;
	color: #00A3FF;
}
</style>